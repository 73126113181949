::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff7800;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3cb815;
}

.border-box {
  box-sizing: border-box;
}

.swiper-pagination-bullet {
  width: 2.5rem !important;
  height: 7px !important;
  background: #3cb815 !important;
  /* opacity: 1; */
  border-radius: 2px !important;
}

.swiper-pagination-bullet-active {
  background: #f65005 !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 3rem;
  height: 3rem;
  background: #3cb815;
  border-radius: 50%;
  color: #fff;
}

.swiper-button-prev,
.swiper-button-next {
  top: 42%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 13px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.store_Slider .swiper-button-prev {
  left: 0 !important;
}

.store_Slider .swiper-button-next {
  right: 0 !important;
}

.store_Slider .swiper-button-next:after,
.store_Slider .swiper-button-prev:after {
  font-size: 14px !important;
}

@media screen and (max-width: 620px) {
  .mobi-grid {
    width: 50%;
  }
}

.cat-img {
  width: 90px;
  height: 90px;
  border: 2px solid #d4cfcf;
}

.cat-list-img {
  width: 142px;
  height: 142px;
  border: 2px solid #d4cfcf;
}

.product__grid--column__button {
  border: 1px solid #aeaebf;
  background: #fff;
  border-radius: 0.3rem;
  line-height: 2rem;
  margin-right: 10px;
  text-align: center;
  padding: 16px;
  cursor: pointer;
}

.product__grid--column__button.active {
  color: #3cb815;
  border-color: #3cb815;
}

.product-list-title a {
  line-height: 2.2rem;
  overflow: hidden;
  display: block;
  max-height: 4.4rem;
  -webkit-line-clamp: 1;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.cursor-pointer {
  cursor: pointer !important;
}

.social-icons {
  width: 80px;
}

.custom-navtabs {
  overflow: hidden;
  overflow-x: hidden;
  padding: 10px;
}

.product-detail-tabs {
  overflow: hidden;
  overflow-x: scroll;
  padding: 10px;
}

.nav {
  flex-wrap: nowrap;
}

.custom-navtabs .nav-pills .nav-item {
  margin-right: 2rem;
  /* width: 20rem; */
}

.custom-navtabs .nav-pills .nav-link {
  width: 19rem;
}

.custom-navtabs::-webkit-scrollbar-thumb {
  border-radius: 20px;
  width: 5px !important;
  height: 5px !important;
  background: #dbdbdb !important;
}

@media screen and (max-width: 991px) {

  .col-xs-6,
  .col-sm-6 {
    width: 50%;
  }
}

.is__selected {
  background-color: #3cb815 !important;
  color: #fff;
  border: #3cb815 !important;
}

.flex-wrap {
  flex-wrap: wrap;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #3cb815 !important;
  background-color: transparent;
}

.nav-pills .nav-link {
  font-size: 1.8rem;
  color: #000;
}

.nav-pills .nav-item {
  margin-right: 5rem;
}

.nav-pills .nav-item .nav-link.active {
  border-bottom: 2px solid #3cb815;
  border-radius: 0px;
}

.text-wrap-4 {
  line-height: 2.2rem;
  overflow: hidden;
  display: block;
  max-height: 8.4rem;
  -webkit-line-clamp: 4;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.text-wrap-1 {
  line-height: 2.2rem;
  overflow: hidden;
  display: block;
  max-height: 2.4rem;
  -webkit-line-clamp: 1;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.modal-backdrop {
  background-color: transparent !important;
}
